header {
  position: fixed;
  width: 100%;
  z-index: 12;
}
//bulma alterations

.nav {
  background-color: $black;
  color: $orange;

  img {
    max-height: 3.75rem;
  }

  a {
    &.nav-item {
      transition: 0.75s ease;

      &:not(.button):hover {
        color: $orange;
      }
    }
  }

  &-toggle,
  &-toggle.is-active {
    &:hover {
      background-color: $black;
    }

    span {
      background-color: $orange;
    }
  }
}
