$nav-height: 5rem;
@import "../../node_modules/bulma/bulma";
@import "../../node_modules/font-awesome/scss/font-awesome";
@import "../../node_modules/lightgallery/src/sass/lightgallery";
@import "common/colors";
@import "common/typography";
@import "common/mixins";
@import "components/header";
@import "sections/intro";
@import "sections/polimento";
@import "sections/aspectos";
@import "sections/gallery";
@import "components/banners";
@import "components/contact";

*, *:after, *:before {
  box-sizing: border-box;
}

.left-border{
  padding-left: .5rem;
  border-left: 4px solid $orange;
}
.columns {
  margin-top: 1rem;
  .column{
    .column__title {
      text-align: center
    }
  }
}

.margin-top-container {
  margin-top: 2rem;
}

h1, .orange-title {
  color: $orange;
  font-size: 3rem;
}
h2 {
  font-size: 1.75rem;
}

.subtitle--white {
  color: white
}

.inner--fullheight {
  min-height: calc(100vh - 80px);
  .column {
    margin-right: 50px;
  }
  .hero-body{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}