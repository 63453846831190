.main-hero {
  .hero-body {
    //background: linear-gradient( to bottom, rgba(0,0,0,0.65) 0% rgba(0,0,0,0) 100% ),url('../img/hero__bg.jpg');
    background-image: url('../img/hero__bg.jpg');
    //filter: blur(20px) grayscale(20%);
    @include sticky-bg();
  }

  .text-wrapper{
    background: rgba(255,255,255,0.9);
    float: left;
    padding: 4rem 2rem;
    @media screen and (min-width: 960px) {
      width: 50%;
    }
  }

  h2 {
    margin-top: 2rem !important;
  }
}
