.gallery-hero {
  background-color: $gray;
  color: $brown;

  .hero-body {
    //justifica o conteudo do carrousel
    justify-content: center;
  }

  h2{
    margin-top: 0 !important;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &__wrapper {
    min-width: 0;
    min-height: 0;
    align-self: center;
    width: 90%;
  }

  &__item {
    position: relative;
    border: 3px solid white;
    width: 250px;
    height: 250px;
    margin: 1rem;
    transition: transform 0.15s ease 0s;
    transform: scale(1);
    &:hover {
      transform: scale3d(1.1, 1.1, 1.1);
      transition: transform 0.15s ease 0s;
      .zoom {
        display: block;
        &-wrapper {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.zoom {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
